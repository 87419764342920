import * as React from "react"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"

const CapitalMarketsPage = () => (
  <Layout>
    <Seo title="Capital Markets" />
    <div id="content">
      <div id="feature_box"></div>
      <div class="post_box top" id="post-7">
        <div class="format_text">
          <h1>Capital Markets</h1>
          <p>
            <span
              style={{
                float: "left",
                margin: "0 0.5em 0.5em 0.5em",
                textAlign: "left",
                display: "block",
              }}
            >
              <StaticImage
                src="../images/shutterstock_11829016-300x199.jpeg"
                alt="Wall Street"
              />
            </span>
            For young high-growth phase enterprises it is critically important
            to understand investment banking relationships and the different
            capabilities of various investment banking firms (geographical
            footprint, research capability, sales force and securities
            distribution prowess).&nbsp;It is also critical to understand
            whether or not your company will be materially important enough to
            deserve the attention of a particular investment banking
            firm.&nbsp;Following the demise of major Wall Street firms during
            the 2008-09 financial crisis, having investment banking experience
            on the board may make the difference between enterprise success and
            failure.&nbsp;This does not mean having someone whose agenda is to
            provide investment banking work solely to the firm where they are
            associated. Rather, a young company might best be served by
            experienced investment bankers specializing in their industry niche,
            while mature Fortune 500 issuers will be best served by those having
            “major bracket” investment banker experience.
          </p>
          <p>
            Larry Trautman has extensive capital markets experience and
            expertise. As Senior Vice President of the New York-based investment
            banking firm Donaldson, Lufkin &amp; Jenrette (now{" "}
            <a href="https://www.credit-suisse.com/us/en/" target="_self">
              Credit Suisse
            </a>
            ), he has enjoyed a diverse corporate finance practice, having sole
            client responsibility for the origination, negotiation and
            processing of $billions (current market value) of merger and
            acquisition transactions, and work on public equity offerings for
            both traded and going-public situations. His other transaction
            experience includes: venture capital, real estate, public finance,
            and substantial private debt and equity placement. He taught
            accounting and finance at several colleges and universities.
          </p>
          <h1>Investor Relations</h1>
          <p>
            <span
              style={{
                float: "left",
                margin: "0 0.5em 0.5em 0.5em",
                textAlign: "left",
                display: "block",
              }}
            >
              <StaticImage
                src="../images/shutterstock_10041280-Investor-Relations-300x199.jpeg"
                alt="Glasses on a newspaper"
              />
            </span>
            Creating a compelling return-on-equity for shareholders and the
            ability to effectively communicate impressive achievements may prove
            to be entirely different skills. Communicating a corporate value
            proposition to existing and potential investors is critically
            important to the development of a healthy shareholder base comprised
            of investors who will benefit from enterprise growth over-time.
            Investor relations is a strategic management responsibility that
            integrates finance, communication, marketing and securities law
            compliance to enable the most effective two-way communication
            between a company, the financial community, and other
            constituencies, which ultimately contributes to a company’s
            securities achieving fair valuation.
          </p>
          <p>
            Over the years, Mr. Trautman has been a member and attended
            functions of the{" "}
            <a href="http://www.niri.org/">
              National Investor Relations Institute
            </a>
            .&nbsp;The organization is “dedicated to advancing the practice of
            investor relations and professional competency and stature of its
            members”.
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default CapitalMarketsPage
